<template>
	<div class="rightSide">
		<div class="rightSide_inner">
			<div class="rightSide_inner_title">
				<p style="font-size: 30px; color: #333333">{{ $t("Order Details") }}</p>
			</div>

			<div class="rightSide_inner_header">
				<div class="rightSide_inner_header_content">
					<div class="rightSide_order">
						<p>{{ $t("Order Number") }}：</p>
						<p>{{orderInfo.order_sn}}</p>
            <p style="padding-left: 10px">
              <el-button type="button" @click="handleExport">{{ $t('Export') }}</el-button>
            </p>
					</div>
					<div class="rightSide_order">
						<p>{{ $t("Time") }}：</p>
						<p>{{orderInfo.created_at | formatDateStr}}</p>
					</div>
				</div>
			</div>
			<div class="rightSide_total">
				<p style="font-size: 16px; color: #333333">{{ $t("Successful order") }}：</p>
				<p style="font-size: 30px; color: #218da0">￥{{ orderInfo.make_total }}</p>
				&nbsp;&nbsp;&nbsp;
				&nbsp;&nbsp;&nbsp;
				<p style="font-size: 16px; color: #333333">{{ $t("Total") }}：</p>
				<p style="font-size: 30px; color: #218da0">￥{{ orderInfo.total }}</p>
			</div>
			<p style="font-size: 22px; color: #333333; margin-top: 20px">
				{{ $t("Item Information") }}
			</p>

			<div class="table_style">
				<el-table ref="multipleTable" header-align="center" border class="main-table" :data="tableData"
					tooltip-effect="dark" style="width: 100%; font-size: 18px; color: #515a6e"
					:header-cell-style="{ color: 'black' }">
					<el-table-column :label="$t('Picture')" prop="img" align="center">
						<template slot-scope="scope">
							<img :src="`${scope.row.img}`" class="mr-3" height="80" width="80" alt="..." />
						</template>
					</el-table-column>
					<el-table-column prop="item_en" :label="$t('Name')" align="center" />
					<el-table-column prop="Sku" :label="$t('Product Barcode')" align="center" />
					<el-table-column prop="spec" :label="$t('MOQ')" align="center" />

					<el-table-column prop="goods_number" :label="$t('Qty')" align="center">
						<template slot-scope="{row}">
							<div v-if="row.order_status == 0">
								<el-input-number v-model="row.goods_number" :min="row.spec" :step="row.spec" size="mini"
									@change="(currentValue, oldValue) =>handleChange(currentValue, oldValue, row)">
								</el-input-number>
							</div>

							<div v-else>
								{{row.goods_number}}
							</div>
						</template>
					</el-table-column>

					<el-table-column :label="$t('Purchase Price')" align="center">
						<template slot-scope="scope">
							￥{{ scope.row.goods_price }}
						</template>
					</el-table-column>

					<el-table-column :label="$t('status')" align="center">
						<template slot-scope="{row}">
							<span v-if="row.order_status == 0">
								{{orderStatus[row.order_status]}}
							</span>

							<span v-if="row.order_status == 1" style="color: green;">
								{{orderStatus[row.order_status]}}
							</span>

							<span v-if="row.order_status==2" style="color: red;">
								{{orderStatus[row.order_status]}}
							</span>
						</template>
					</el-table-column>

					<el-table-column :label="$t('Subtotal')" align="center">
						<template slot-scope="scope">
							￥{{ scope.row.goods_total }}
						</template>
					</el-table-column>

					<el-table-column :label="$t('Operation')" align="center">
						<template slot-scope="{row}">
							<div v-if="row.order_status == 0">
								<el-button size="mini" @click="delOrderGoods(row)">{{$t('Delete')}}</el-button>
							</div>
							<div v-else>
								~
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>
<script>
import {
  getPresellOrderInfo,
  getOrderGoodsByOrderSn,
  upOrderGoodsNum,
  delOrderGoods, getDownPresellGoods,
} from "@/api/goods/presell.js";
	import common from "@/common/mixins/common.js";
	export default {
		mixins: [common],
		components: {},
		data() {
			return {
				orderInfo: {},
				tableData: [],
				orderTotal: 0,
				// orderStatus:{
				// 	"en":{
				// 		"0":"待审核",
				// 		"1":"成功",
				// 		"2":"失败",
				// 	},
				// 	"cn":{
				// 		"0":"To be processed",
				// 		"1":"Success",
				// 		"2":"Fail",
				// 	}
				// }
				orderStatus: {}
			};
		},
		created() {
			this.getOrderInfo();
			this.getOrderGoodsList();
			this.SetGoodsStatus(); //设置订单状态
		},
		watch: {

		},
		methods: {
			//设置订单状态
			SetGoodsStatus() {
				if (this.$i18n.locale == "zh-CN") {
					this.orderStatus = {
						"0": "待审核",
						"1": "成功",
						"2": "失败"
					}
				} else {
					this.orderStatus = {
						"0": "To be processed",
						"1": "Success",
						"2": "Fail"
					}
				}
			},
			//修改购物车产品
			handleChange(currentValue, oldValue, rowData) {
				let putData = {
					"order_goods_id": rowData.id,
					"num": currentValue
				}
				if((currentValue % rowData.spec) > 0){
					putData.num = oldValue
				} else {
					putData.num = currentValue
				}
				//修改订单商品数量
				upOrderGoodsNum(putData).then(() => {
					this.getOrderInfo();
					this.getOrderGoodsList();
				})
			},
			//获取订单详情
			getOrderInfo() {
				let orderSn = parseInt(this.$route.query.orderSn);
				getPresellOrderInfo(orderSn).then((res) => {
					this.orderInfo = res.data.data;
				});
			},
      //删除订单商品
      delOrderGoods(row){
        delOrderGoods(row.id).then(()=>{
          this.getOrderGoodsList()
        })
      },
			//获取到订单里的商品信息
			getOrderGoodsList() {
				let orderSn = parseInt(this.$route.query.orderSn);
				getOrderGoodsByOrderSn(orderSn).then((res) => {
					let resData = res.data.data
					resData = this.filterData(resData)
					this.tableData = resData
				})
			},
			filterData(data) {
				for (let key in data) {
					if (this.$i18n.locale == "zh-CN") {
						data[key]["item_en"] = data[key]["item_name"];
					}
				}
				return data
			},
      handleExport(){
        getDownPresellGoods({"id" : this.orderInfo.id }).then((res) => {
          //window.open();
          location.href=this.$root.DownUrl + "/c1/down/" + res.data.data;
        });
      },
		},
	};
</script>
<style lang="scss" scoped>
	.rightSide {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		width: 1320px;
		height: 786px;
		overflow: scroll;
		margin: 15px 0 0 15px;
		background-color: #ffffff;

		&_inner {
			width: 1218px;

			&_title {
				width: 1218px;
				height: 68px;
				padding-top: 20px;
			}

			&_header {
				width: 1218px;
				height: 58px;
				border-bottom: 1px solid #c0c0cc;
				margin-top: 15px;
				display: flex;
				align-items: center;
				justify-content: flex-start;

				&_content {
					width: 1218px;
					height: 27px;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}
		}

		&_order {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-size: 18px;
			color: #333333;
		}

		&_total {
			width: 1218px;
			height: 43px;
			margin-top: 25px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	.table_style {
		width: 1218px;
		margin-top: 30px;
	}
</style>
<style lang="scss">
	.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #218da0; //修改后的背景图颜色
		color: #fff;
	}
</style>
